<template>
  <v-toolbar color="primary" density="default" class="px-3 px-lg-16">
    <div class="appbar-dp d-flex justify-space-between">
      <v-img
        alt="logo"
        contain
        max-width="120"
        min-width="120"
        :src="blobStorageImagesUrl + 'TBSLogo.png'"
        @click="$router.push('/welcome')"
      />

      <v-spacer></v-spacer>
      <div class="mx-3 d-none d-lg-block">
        <v-btn
          v-for="(dp_link, i) in dp_links"
          :key="i"
          @click="$router.push(dp_link.href)"
          :disabled="dp_link.disabled"
          class="my-3"
          small
          text
        >
          {{ dp_link.text }}
        </v-btn>
      </div>
      <div class="d-lg-none">
        <v-menu
          bottom
          min-width="200px"
          rounded="xl"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <div v-for="(dp_link, i) in dp_links" :key="i">
                  <v-btn
                    @click="$router.push(dp_link.href)"
                    depressed
                    rounded
                    text
                    :disabled="dp_link.disabled"
                  >
                    {{ dp_link.text }}
                  </v-btn>
                  <v-divider class="my-3"></v-divider>
                </div>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </div>
      <v-menu min-width="200px">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" class="my-auto">
            <v-avatar color="white" size="large">
              <v-img
                alt="Avatar"
                contain
                src="https://staccimagesfronttest.blob.core.windows.net/draypowerimages/app-bar-images/supra_logo.png"
              ></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <!-- <v-card rounded="xl">
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar color="white">
                <v-img
                  alt="Avatar"
                  src="https://staccimagesfronttest.blob.core.windows.net/draypowerimages/app-bar-images/supra_logo.png"
                ></v-img>
              </v-avatar>
              <h3>Test User</h3>
              <p class="text-caption mt-1">Admin</p>
              <v-divider class="my-3"></v-divider>
              <v-btn rounded variant="text"> Log Out </v-btn>
            </div>
          </v-card-text>
        </v-card> -->
      </v-menu>
    </div>
  </v-toolbar>
</template>

<script>
import endpoints from "../utils/endpoints";

export default {
  name: "NavBar",
  data() {
    return {
      dp_links: [],
      blobStorageImagesUrl: endpoints.blobStorageImages(),
    };
  },

  created() {
    fetch(endpoints.dynamicListNavbarItems())
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al cargar el JSON");
        }
        return response.json();
      })
      .then((data) => {
        this.dp_links = data;
      })
      .catch((error) => {
        console.error("Error al cargar el JSON:", error.message);
      });
  },
  /* computed: {
    user() {
      return this.$store.getters.user;
    },
  }, */
  methods: {
    /* signOut() {
      this.$store.dispatch("signOut");
    }, */
    isActiveRoute(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>

<style scoped lang="scss">
.appbar-dp {
  width: 100%;
}
</style>
