const endpoints = {
  dynamicListNavbarItems() {
    return process.env.VUE_APP_DYNAMICLISTST_URL + "navbaritems.json";
  },
  blobStorageImages() {
    return process.env.VUE_APP_BLOBSTORAGEIMAGES_URL;
  },
};

export default endpoints;
